import * as React from "react";

import { Layout } from "../common/Layout";
import { SEO } from "../components/seo";

function NotFoundPage() {
    return (
        <Layout>
            <SEO title="404: Not found" />
            <div>
                <h2 className="text-2xl font-bold inline-block my-8 p-3">
                    Whoops, this page has vanished. Sorry, we'll try to track it
                    down...
                </h2>
            </div>
        </Layout>
    );
}

export default NotFoundPage;
